import React from "react";
import {
	DashColumn,
	DashMaster,
	DashNext,
	LearnCourse,
	LearnProgressBar,
	ProfileBlock,
	ProfileImageCircle
} from "./Dashboard.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { fetchUser } from "../../services/User.services";
import { auth } from "../../firebase";
import CollegeList from "../../components/CollegeDecisionModules/CollegeList";
import { LearnWorldsUserProgressResponse, UserProgress } from "../../types/LearnWorlds.types";
import { fetchLWAccess } from "../../services/Learn.services";

export default function Dashboard(props: { setTool: any; learn: LearnWorldsUserProgressResponse | undefined }) {
	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(""),
		refetchOnWindowFocus: false,
		staleTime: 600000
	});

	const getURL = useMutation({
		mutationKey: ["learn"],
		mutationFn: (body: any) => fetchLWAccess(body),
		onSuccess: (response) => {
			window.open(response);
		}
	});

	const user = auth.currentUser;
	const croiLearn = props.learn ? props.learn.data.find((a) => a.course_id == "croilearn") : undefined;
	const fafsa = props.learn ? props.learn.data.find((a) => a.course_id == "fafsa-step-by-step") : undefined;

	const isAdminCheck = (check: boolean) => {
		if (check) {
			return <u onClick={() => props.setTool("Admin")}>Admin Panel</u>;
		}
	};

	const nextUnit = (data: UserProgress) => {
		for (let i = 0; i < data.progress_per_section_unit.length; i++) {
			for (let j = 0; j < data.progress_per_section_unit[i].units.length; j++) {
				if (data.progress_per_section_unit[i].units[j].unit_status != "completed") {
					const num = i + j + 1;
					return `${num > 9 ? num : "0" + num} ${data.progress_per_section_unit[i].units[j].unit_name}`;
				}
			}
		}
	};

	return (
		<DashMaster>
			<DashColumn>
				<ProfileBlock>
					{user?.photoURL ? (
						<ProfileImageCircle imageUrl={user.photoURL} />
					) : (
						<ProfileImageCircle
							imageUrl={"images/profileLogo.png"}
							style={{
								backgroundSize: "50%",
								backgroundRepeat: "no-repeat"
							}}
						/>
					)}

					<div>
						<p>
							{getUser.data?.firstName} {getUser.data?.lastName}
						</p>
						<p>{isAdminCheck(getUser.data?.isAdmin ? getUser.data?.isAdmin : false)}</p>
						<u onClick={() => props.setTool("Settings")}>Account Settings</u>
					</div>
				</ProfileBlock>

				<DashNext>
					<h3>What's Next</h3>
					<LearnCourse onClick={() => getURL.mutate({ course_id: "croilearn" })}>
						<h4>Financial Literacy Education</h4>
						{croiLearn ? (
							<>
								<span>
									{croiLearn.status == "completed" ? (
										<b>Done!</b>
									) : (
										<>
											<b>Next Section:</b> <i>{nextUnit(croiLearn)}</i>
										</>
									)}
								</span>
								<LearnProgressBar>
									<div className={"progress"} style={{ width: `${croiLearn.progress_rate}%` }}></div>
								</LearnProgressBar>
								<p>{croiLearn.progress_rate}% Complete</p>
							</>
						) : (
							<p>croiLearn Not Started</p>
						)}
					</LearnCourse>
					<LearnCourse onClick={() => getURL.mutate({ course_id: "fafsa-step-by-step" })}>
						<h4>Financial Aid Education</h4>
						{fafsa ? (
							<>
								<span>
									{fafsa.status == "completed" ? (
										<b>Done!</b>
									) : (
										<>
											<b>Next Section:</b> <i>{nextUnit(fafsa)}</i>
										</>
									)}
								</span>
								<LearnProgressBar>
									<div className={"progress"} style={{ width: `${fafsa.progress_rate}%` }}></div>
								</LearnProgressBar>
								<p>{fafsa.progress_rate}% Complete</p>
							</>
						) : (
							<p>FAFSA Step-By-Step Not Started</p>
						)}
					</LearnCourse>
				</DashNext>
			</DashColumn>

			<DashColumn>
				<CollegeList user={getUser.data!} />
			</DashColumn>
		</DashMaster>
	);
}

import React, { useState } from "react";
import {
	ApplyButton,
	FederalSection,
	FedLenderCard,
	InfoBox,
	LenderImage,
	LenderSection,
	LoanChangeButton,
	LoanChart,
	LoanGraph,
	LoanRow,
	MobileAddButton,
	ModalStyle,
	PrivateSection,
	PrivLenderCard,
	ResetButton,
	SLTButton,
	SLTOptions
} from "./StudentLoan.styles";
import { currencyFormatter } from "../../util/currencyFormatter";
import { loan } from "../../types/Loan.types";
import LoanBarChart from "../../components/LoanBarChart";
import { useMutation } from "@tanstack/react-query";
import { addLoan, deleteLoan, updateLoan } from "../../services/Loan.services";
import { Modal } from "react-bootstrap";
import { queryClient } from "../../services/QueryClient.services";
import { orderBy } from "lodash";
import privateList from "../../atoms/PrivateLendorList";
import federalList from "../../atoms/FederalLendorList";
import { College } from "../../types/College.types";
import { Snack } from "../../atoms/SnackBarAlert";
import MobileLoanTab from "../../components/MobileLoanTab/MobileLoanTab";

export default function StudentLoan(props: { colleges: College[]; loans: loan[] }) {
	const [show, setShow] = useState(false);
	const [option, setOption] = useState("Add");
	const [showDropDown, setShowDropDown] = useState(false);
	const [snackMsg, setMsg] = useState("");
	const [selectedName, setSelectedName] = useState(props.colleges[0] ? props.colleges[0].name : "");
	const [selectedID, setSelectedID] = useState(props.colleges[0] ? props.colleges[0].id : "");

	const loanDefault: loan = {
		id: "",
		uid: "",
		cid: "0",
		name: `Loan ${props.loans.length + 1}`,
		classYear: "Freshman",
		amount: 0,
		interest: 0,
		term: 0,
		federal: true,
		subsidized: true,
		pay: 0
	};

	const [data, setData] = useState(loanDefault);

	const createLoan = useMutation({
		mutationKey: ["loans"],
		mutationFn: (body: any) => addLoan(body),
		onSuccess: () => {
			setMsg("Created Loan Successfully!");
			setShow(false);
			queryClient.invalidateQueries(["loans"]);
		}
	});

	const changeLoan = useMutation({
		mutationKey: ["loans"],
		mutationFn: (body: any) => updateLoan(body),
		onSuccess: () => {
			setMsg("Edited Loan Successfully!");
			setShow(false);
			queryClient.invalidateQueries(["loans"]);
		}
	});

	const delLoan = useMutation({
		mutationKey: ["loans"],
		mutationFn: (body: any) => deleteLoan(body),
		onSuccess: () => {
			setMsg("Deleted Loan Successfully!");
			queryClient.invalidateQueries(["loans"]);
		}
	});

	const monthlyCalc = (loaner: loan) => {
		const interest = loaner.interest / 100 / 12;
		const months = 12 * loaner.term;
		const fixed = Math.pow(1 + interest, months);
		return (loaner.amount / (fixed - 1)) * (interest * fixed) + loaner.pay;
	};

	const totalCalc = () => {
		let total = 0;

		for (let i = 0; i <= props.loans.length - 1; i++) {
			if (props.loans[i].cid === selectedID || selectedName === "No School Selected") {
				total += monthlyCalc(props.loans[i]) * props.loans[i].term * 12;
			}
		}

		return total;
	};

	const mostTime = () => {
		let time = 0;

		for (let i = 0; i <= props.loans.length - 1; i++) {
			if (props.loans[i].cid === selectedID || selectedName === "No School Selected") {
				if (props.loans[i].term > time) {
					time = props.loans[i].term;
				}
			}
		}

		return time;
	};

	const loanChecker = () => {
		const loans = [];

		for (let i = 0; i <= props.loans.length - 1; i++) {
			if (props.loans[i].cid === selectedID || selectedName === "No School Selected") {
				loans.push(props.loans[i]);
			}
		}

		return loans;
	};

	const addFunc = () => {
		setShow(true);
		setOption("Add");
		setData(loanDefault);
	};

	const editFunc = (LoanInfo: loan) => {
		setShow(true);
		setOption("Edit");
		setData(LoanInfo);
	};

	const modalDone = (l: loan) => {
		const body = {
			id: l.id,
			cid: l.cid,
			name: l.name,
			classYear: l.classYear,
			amount: l.amount,
			interest: l.interest,
			term: l.term,
			federal: l.federal,
			subsidized: l.subsidized,
			pay: l.pay
		};

		if (option === "Add") {
			if (l.amount <= 0) {
				setMsg("Please enter an amount.");
			} else if (l.interest <= 0) {
				setMsg("Please enter an interest.");
			} else if (l.term <= 0) {
				setMsg("Please enter a term.");
			} else {
				createLoan.mutate(body);
			}
		} else if (option === "Edit") {
			changeLoan.mutate(body);
		}
	};

	const deleteFunc = (LoanId: string) => {
		const body = { id: LoanId };
		delLoan.mutate(body);
	};

	const loanRenderer = orderBy(props.loans).map((p) => {
		if (p.cid === selectedID) {
			return (
				<LoanRow>
					<td style={{ width: "5%" }}>
						<LoanChangeButton onClick={() => editFunc(p)}>+</LoanChangeButton>
					</td>
					<td>{p.name}</td>
					<td>{p.classYear}</td>
					<td>{currencyFormatter.format(p.amount)}</td>
					<td>{p.interest}%</td>
					<td>{p.term} Year</td>
					<td>{p.federal ? "Federal" : "Private"}</td>
					<td>{p.subsidized ? "Subsidized" : "Unsubsidized"}</td>
					<td>{currencyFormatter.format(p.pay)}</td>
					<td>{currencyFormatter.format(monthlyCalc(p))}</td>
					<td style={{ width: "5%" }}>
						<LoanChangeButton onClick={() => deleteFunc(p.id)}>X</LoanChangeButton>
					</td>
				</LoanRow>
			);
		}
	});

	const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
		if (event.currentTarget === event.target) {
			setShowDropDown(false);
		}
	};

	const mapper = props.colleges.map((school: College) =>
		props.colleges.length > 0 ? (
			<SLTOptions
				onClick={(): void => {
					setSelectedName(school.name);
					setSelectedID(school.id);
				}}>
				{school.name}
			</SLTOptions>
		) : (
			<></>
		)
	);

	const modalView = () => {
		const classYearOptions = ["Freshman", "Sophomore", "Junior", "Senior"];

		return (
			<Modal show={show} onHide={() => setShow(!show)}>
				<ModalStyle>
					<Modal.Title style={{ margin: "10px" }}>{option} Loan</Modal.Title>
					<div>
						<p>Name: </p>
						<input
							type="text"
							placeholder={"name"}
							value={data.name}
							onChange={(e) => setData({ ...data, name: e.target.value })}
						/>
					</div>

					<div>
						<p>Class Year: </p>
						<select value={data.classYear} onChange={(e) => setData({ ...data, classYear: e.target.value })}>
							{classYearOptions.map((year, index) => (
								<option key={index} value={year}>
									{year}
								</option>
							))}
						</select>
					</div>

					<div>
						<p>Amount: </p>
						<input
							type="number"
							placeholder={"amount"}
							value={data.amount}
							onChange={(e) => setData({ ...data, amount: e.target.valueAsNumber })}
							min={0}
						/>
					</div>

					<div>
						<p>Interest: </p>
						<input
							type="number"
							placeholder={"interest"}
							step={0.001}
							value={data.interest}
							onChange={(e) => {
								setData({ ...data, interest: e.target.valueAsNumber });
							}}
						/>
					</div>

					<div>
						<p>Term: </p>
						<input
							type="number"
							placeholder={"term"}
							value={data.term}
							onChange={(e) => setData({ ...data, term: e.target.valueAsNumber })}
							min={0}
							max={100}
						/>
					</div>

					<div>
						<p>Private/ Federal: </p>
						<select value={data.federal ? 1 : 0} onChange={(e) => setData({ ...data, federal: e.target.value == "1" })}>
							<option key={"FedTrue"} value={1}>
								Federal
							</option>
							<option key={"FedFalse"} value={0}>
								Private
							</option>
						</select>
					</div>

					<div>
						<p>Subsidized/ Unsubsidized: </p>
						<select
							value={data.subsidized ? 1 : 0}
							onChange={(e) => setData({ ...data, subsidized: e.target.value == "1" })}>
							<option key={"SubTrue"} value={1}>
								Subsidized
							</option>
							<option key={"SubFalse"} value={0}>
								Unsubsidized
							</option>
						</select>
					</div>

					<div>
						<p>Payment: </p>
						<input
							type="number"
							placeholder={"extra payment per month"}
							value={data.pay}
							onChange={(e) => {
								setData({ ...data, pay: e.target.valueAsNumber });
							}}
							min={0}
							max={data.amount}
						/>
					</div>

					<ResetButton
						onClick={() => {
							const temp: loan = {
								id: data.id,
								uid: data.uid,
								cid: selectedID,
								name: data.name,
								classYear: data.classYear,
								amount: data.amount,
								interest: data.interest,
								term: data.term,
								federal: data.federal,
								subsidized: data.subsidized,
								pay: data.pay
							};

							modalDone(temp);
						}}
						style={{ margin: "10px" }}>
						Done
					</ResetButton>
				</ModalStyle>
			</Modal>
		);
	};

	const resetEvent = (): void => {
		const loanList = props.loans;

		for (let i = 0; i < loanList.length; i++) {
			deleteFunc(loanList[i].id);
		}
	};

	const federalLenderCard = federalList.map((p) => {
		return (
			<FedLenderCard>
				<h4>{p.name}</h4>
				<LenderImage imageUrl={p.image} />
				<a href={p.link}>
					<ApplyButton>Apply</ApplyButton>
				</a>
			</FedLenderCard>
		);
	});

	const privateLenderCard = privateList.map((p) => {
		return (
			<PrivLenderCard>
				<h4>{p.name}</h4>
				<LenderImage imageUrl={p.image} />
				<a href={p.link}>
					<ApplyButton>Apply</ApplyButton>
				</a>
			</PrivLenderCard>
		);
	});

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			<SLTButton
				className={showDropDown ? "active" : undefined}
				onClick={() => setShowDropDown(!showDropDown)}
				onBlur={(e: React.FocusEvent<HTMLButtonElement>): void => dismissHandler(e)}>
				{" "}
				Select School
				{showDropDown && (
					<div className={showDropDown ? "dropdown" : "dropdown active"}>
						{/* {allLoans} */}
						{mapper}
					</div>
				)}
			</SLTButton>
			{modalView()}
			<h2
				style={{
					width: "40%",
					textAlign: "center",
					marginLeft: "auto",
					marginRight: "auto",
					marginTop: "30px",
					borderBottom: "black solid 3px"
				}}>
				{selectedName}
			</h2>
			<h2 style={{ textAlign: "center", marginTop: "20px" }}>Payment Calculator</h2>
			{window.outerWidth <= 412 ? (
				<>
					{props.loans.length > 0 ? (
						props.loans.map((loan) => {
							if (loan.cid == selectedID) {
								return <MobileLoanTab loan={loan} setData={setData} setOption={setOption} showModal={setShow} />;
							} else {
								return <></>;
							}
						})
					) : (
						<></>
					)}
					<MobileAddButton onClick={() => addFunc()}>+ Add a Loan</MobileAddButton>
				</>
			) : (
				<LoanChart>
					<LoanRow>
						<th style={{ width: "5%" }}>Edit</th>
						<th>Loan</th>
						<th>Class Year</th>
						<th>Amount ($)</th>
						<th>Interest Rate</th>
						<th>Loan Term</th>
						<th>Pri/Fed</th>
						<th>Sub/Unsub</th>
						<th>Monthly Pre-Pay</th>
						<th style={{ background: "var(--croi-green)", color: "white" }}>Monthly Payment</th>
						<th style={{ width: "5%" }}>Delete</th>
					</LoanRow>
					{props.loans.length > 0 ? loanRenderer : ""}
					<LoanRow style={{ height: "60px", justifyContent: "space-between", padding: "10px" }}>
						<tfoot style={{ marginTop: "7px" }}>
							<LoanChangeButton onClick={() => addFunc()}>+</LoanChangeButton>
							<>Add a Loan</>
						</tfoot>
						<ResetButton onClick={() => resetEvent()}>Reset</ResetButton>
					</LoanRow>
				</LoanChart>
			)}

			{props.loans.length > 0 ? (
				<InfoBox>
					Total Cost (with interest) = <b>{currencyFormatter.format(totalCalc())} </b>
					over <b>{mostTime()} years</b>.
				</InfoBox>
			) : (
				<InfoBox>No loans yet!</InfoBox>
			)}
			<LoanGraph>
				<h4>Loan Balance Over Time</h4>
				{props.loans.length > 0
					? loanChecker().length > 0
						? LoanBarChart(loanChecker())
						: "No loans to render"
					: "No loans to render"}
			</LoanGraph>

			<LenderSection>
				<hr />
				<h2>Federal Loans</h2>
				<FederalSection>{federalLenderCard}</FederalSection>
				<h2>Private Loans</h2>
				<PrivateSection>{privateLenderCard}</PrivateSection>
			</LenderSection>
		</>
	);
}

import axios, { AxiosResponse } from "axios";
import { auth } from "../firebase";

const base = process.env.REACT_APP_USER_API_URL;

export const fetcher = async <T>(url: string, method: string): Promise<T> => {
	const axiosInstance = axios.create({
		baseURL: base,
		timeout: 7500
	});

	const idToken = await auth.currentUser!.getIdToken();

	const res = await axiosInstance({
		url: url,
		method,
		headers: { Authorization: `Bearer ${idToken}` }
	}).then((res: AxiosResponse) => res.data);

	return new Promise((resolve, reject) =>
		setTimeout(() => {
			if (res.status !== 500) {
				resolve(res as T);
			} else {
				reject(res);
			}
		}, 1)
	);
};

import {
	CloseXButton,
	PricingBox,
	PricingBoxLarge,
	PricingCard,
	PricingRow,
	SettingsWindowBackdrop,
	SettingsWindowBox,
	SmallBanner,
	UpgradeButton
} from "./SettingsWindow.styles";
import { useMutation } from "@tanstack/react-query";
import { deleteUser, updateUser } from "../../services/User.services";
import { auth } from "../../firebase";
import React, { useState } from "react";
import { reauthenticateWithCredential, updatePassword } from "firebase/auth";
import firebase from "firebase/compat/app";
import { queryClient } from "../../services/QueryClient.services";
import { User } from "../../types/User.types";
import { paymentCheckout } from "../../services/Stripe.services";

export default function SettingsWindow(props: { type: string; setWindow: any; user: User; setMsg: any }) {
	const getCheckout = useMutation({
		mutationKey: ["payments"],
		mutationFn: (body: any) => paymentCheckout(body),
		onSuccess: (res) => {
			window.location.assign(res);
		}
	});

	const checkout = (plan: "Annual" | "Monthly") => {
		getCheckout.mutate({
			url: window.location.origin + window.location.pathname,
			productID: plan == "Monthly" ? process.env.REACT_APP_PAYMENT_ONE_KEY : process.env.REACT_APP_PAYMENT_TWELVE_KEY
		});
		props.setWindow(false);
	};

	const deleteUserMutation = useMutation({
		mutationKey: ["users"],
		mutationFn: (body: any) => deleteUser(body),
		onSuccess: () => {
			props.setMsg("Account Deleted!");
			auth.signOut().then(() => window.location.assign(window.location.origin));
		}
	});

	//*************************************** Change Password ****************************************//
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [rePass, setRePass] = useState("");

	const passwordResetHandler = () => {
		const email = auth.currentUser?.email;

		//Checks if user exists
		if (email && auth.currentUser) {
			//Old password & email
			const credential = firebase.auth.EmailAuthProvider.credential(email, oldPass);

			//Check old password
			reauthenticateWithCredential(auth.currentUser, credential)
				.then(() => {
					if (newPass === rePass) {
						updatePassword(auth.currentUser!, newPass)
							.then(() => {
								props.setMsg("Password Change Successful!");
							})
							.catch((error) => {
								//Update Password fail
								props.setMsg(`Error: ${error}`);
							});
					} else {
						//New password entered incorrectly
						props.setMsg("Error: The new password mismatch.");
					}
				})
				.catch((error) => {
					//Old password check fail
					props.setMsg(error);
				});
		} else {
			//User does not exist
			props.setMsg("Error: Email or User not verified.");
		}
	};
	//*************************************** Change Password ****************************************//

	const [first, setFirst] = useState(props.user.firstName);
	const [last, setLast] = useState(props.user.lastName);
	const [zip, setZip] = useState(props.user.zip);
	const [state, setState] = useState(props.user.state);
	const updateUserInfo = useMutation({
		mutationKey: ["users"],
		mutationFn: (body: any) => updateUser(body),
		onSuccess: () => {
			props.setMsg("Information Successfully Updated!");
			queryClient.invalidateQueries(["users"]).then(props.setWindow(false));
		}
	});

	if (props.type == "DELETE") {
		return (
			<>
				<SettingsWindowBackdrop />
				<SettingsWindowBox>
					<h1>Are you Sure? This action is irreversible!</h1>
					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<button
							onClick={() => deleteUserMutation.mutate({ id: auth.currentUser?.uid })}
							style={{ backgroundColor: "red" }}>
							DELETE ACCOUNT
						</button>
						<button onClick={() => props.setWindow(false)}>CANCEL</button>
					</div>
				</SettingsWindowBox>
			</>
		);
	} else if (props.type == "EDIT") {
		return (
			<>
				<SettingsWindowBackdrop />
				<SettingsWindowBox>
					<h1>Edit Personal Information</h1>
					<p>
						<label>First Name:</label>
						<input type={"text"} onChange={(e) => setFirst(e.target.value)} value={first} />
					</p>
					<p>
						<label>Last Name:</label>
						<input type={"text"} onChange={(e) => setLast(e.target.value)} value={last} />
					</p>
					<p>
						<label>State:</label>
						<select onChange={(e) => setState(e.target.value)} value={state}>
							<option></option>
							<option>AL</option>
							<option>AK</option>
							<option>AZ</option>
							<option>AR</option>
							<option>CA</option>
							<option>CO</option>
							<option>CT</option>
							<option>DE</option>
							<option>FL</option>
							<option>GA</option>
							<option>HI</option>
							<option>ID</option>
							<option>IL</option>
							<option>IN</option>
							<option>IA</option>
							<option>KS</option>
							<option>KY</option>
							<option>LA</option>
							<option>ME</option>
							<option>MD</option>
							<option>MA</option>
							<option>MI</option>
							<option>MN</option>
							<option>MS</option>
							<option>MO</option>
							<option>MT</option>
							<option>NE</option>
							<option>NV</option>
							<option>NH</option>
							<option>NJ</option>
							<option>NM</option>
							<option>NY</option>
							<option>NC</option>
							<option>ND</option>
							<option>OH</option>
							<option>OK</option>
							<option>OR</option>
							<option>PA</option>
							<option>RI</option>
							<option>SC</option>
							<option>SD</option>
							<option>TN</option>
							<option>TX</option>
							<option>UT</option>
							<option>VT</option>
							<option>VA</option>
							<option>WA</option>
							<option>WV</option>
							<option>WI</option>
							<option>WY</option>
						</select>
					</p>
					<p>
						<label>Zipcode:</label>
						<input type={"text"} onChange={(e) => setZip(e.target.value)} value={zip} />
					</p>
					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<button
							onClick={() => {
								updateUserInfo.mutate({
									firstName: first,
									lastName: last,
									zip: zip,
									state: state
								});
							}}>
							SAVE
						</button>
						<button onClick={() => props.setWindow(false)}>CANCEL</button>
					</div>
				</SettingsWindowBox>
			</>
		);
	} else if (props.type == "PASSWORD") {
		return (
			<>
				<SettingsWindowBackdrop />
				<SettingsWindowBox>
					<h1>Edit Password</h1>
					<p>
						<label>Old Password:</label>
						<input type={"text"} onChange={(e) => setOldPass(e.target.value)} />
					</p>

					<p>
						<label>New Password:</label>
						<input type={"text"} onChange={(e) => setNewPass(e.target.value)} />
					</p>

					<p>
						<label>Re-Enter Password:</label>
						<input type={"text"} onChange={(e) => setRePass(e.target.value)} />
					</p>

					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<button
							onClick={() => {
								passwordResetHandler();
								props.setWindow(false);
							}}>
							SAVE
						</button>
						<button onClick={() => props.setWindow(false)}>CANCEL</button>
					</div>
				</SettingsWindowBox>
			</>
		);
	} else if (props.type == "UPGRADE") {
		return (
			<>
				<SettingsWindowBackdrop />
				<SettingsWindowBox>
					<PricingBox>
						<CloseXButton onClick={() => props.setWindow(false)}>X</CloseXButton>
						<h1>Upgrade My Plan</h1>
						<p>Unlock access to the full power of the Croi platform.</p>
					</PricingBox>
					<PricingRow>
						<PricingCard>
							<PricingBoxLarge style={{ marginTop: "35px" }}>
								<h4>1 Month</h4>
								<h3>$35 / Month</h3>
								<br></br>
								<br></br>
								<p>Billed Monthly.</p>
								<p>Cancel Anytime.</p>
								<br></br>
								<UpgradeButton onClick={() => checkout("Monthly")}>UPGRADE</UpgradeButton>
							</PricingBoxLarge>
						</PricingCard>
						<PricingCard>
							<SmallBanner>Best Value</SmallBanner>
							<PricingBoxLarge>
								<h4>1 Year</h4>
								<h3>$19 / Month</h3>
								<p style={{ marginTop: "0%" }}>46% Discount!</p>
								<br></br>
								<p>Lowest Monthly Rate.</p>
								<p>Billed Annually.</p>
								<br></br>
								<UpgradeButton onClick={() => checkout("Annual")}>UPGRADE</UpgradeButton>
							</PricingBoxLarge>
						</PricingCard>
					</PricingRow>
				</SettingsWindowBox>
			</>
		);
	} else {
		return <></>;
	}
}

import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { loan } from "../types/Loan.types";
import { currencyFormatter } from "../util/currencyFormatter";
import { round } from "lodash";

type instance = {
	year: number;
	amount: number[];
};

const calcStart = (loan: loan) => {
	if (!loan.subsidized) {
		return 0;
	} else {
		if (loan.classYear === "Freshman") {
			return 4;
		} else if (loan.classYear === "Sophomore") {
			return 3;
		} else if (loan.classYear === "Junior") {
			return 2;
		} else if (loan.classYear === "Senior") {
			return 1;
		}
	}

	return 0;
};

export default function LoanBarChart(loan: loan[]) {
	const colorList = ["#1429c9", "#d92114", "var(--croi-green)", "#cc6c0c", "#b110c9", "#ced113", "#cc0c8c"];

	const calculator = (loan: loan) => {
		const interest = loan.interest / 100 / 12;
		const months = 12 * loan.term;
		const fixed = Math.pow(1 + interest, months);
		const monthly = (loan.amount / (fixed - 1)) * (interest * fixed) + loan.pay;
		const start = calcStart(loan);

		const answer: number[] = [];
		let temp = loan.amount;

		for (let i = 0; i < start; i++) {
			answer.push(loan.amount);
		}

		answer.push(temp);

		for (let j = 1; j < loan.term; j++) {
			for (let i = 0; i < 12; i++) {
				temp += temp * interest - monthly;
			}

			if (temp <= 0) {
				temp = 0;
			}

			answer.push(temp);
		}

		return answer;
	};

	const timelineBuilder = (loan: loan) => {
		const timeline: number[] = [];
		let curYear = new Date().getFullYear();
		const total = loan.term + calcStart(loan);

		for (let i = 0; i <= total - 1; i++) {
			timeline.push(curYear);
			curYear += 1;
		}

		return timeline;
	};

	const longestTimeline = () => {
		let index = 0;
		let term = loan[0].term;

		for (let i = 1; i < loan.length; i++) {
			if (term < loan[i].term) {
				term = loan[i].term;
				index = i;
			}
		}

		return timelineBuilder(loan[index]);
	};

	const dataBuilder = () => {
		const data: instance[] = [];
		const timeline = longestTimeline();
		const amountList: number[][] = [];

		for (let i = 0; i < loan.length; i++) {
			amountList.push(calculator(loan[i]));
		}

		for (let i = 0; i < timeline.length; i++) {
			const amounts = [];

			for (let j = 0; j < loan.length; j++) {
				if (amountList[j][i] !== undefined) {
					amounts.push(amountList[j][i]);
				} else {
					amounts.push(0);
				}
			}

			const instance: instance = {
				year: timeline[i],
				amount: amounts
			};

			data.push(instance);
		}

		console.log("DataBuilder: ", data);
		return data;
	};

	const data = dataBuilder();
	const barRenderer = () => {
		const bars = [];

		for (let i = 0; i < loan.length; i++) {
			const input = "amount[" + i + "]";
			bars.push(<Bar dataKey={input} stackId={"a"} name={loan[i].name} fill={colorList[i]} />);
		}

		return bars;
	};

	const barWidth = () => {
		if (window.innerWidth > 375) {
			return round(window.innerWidth * 0.5);
		} else {
			return round(window.innerWidth * 0.95);
		}
	};

	return (
		<BarChart
			id={`BarChart${window.innerWidth}`}
			width={barWidth()}
			height={300}
			data={data}
			margin={{
				top: 20,
				right: 0,
				left: 25,
				bottom: 5
			}}>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey={"year"} interval={4} />
			<YAxis tickFormatter={(value) => currencyFormatter.format(value)} />
			<Tooltip formatter={(value: number) => currencyFormatter.format(value)} />
			<Legend />
			{barRenderer()}
		</BarChart>
	);
}

import { currencyFormatter } from "../util/currencyFormatter";
import { useMutation } from "@tanstack/react-query";
import { deleteCollege, updateCollege } from "../services/College.services";
import { queryClient } from "../services/QueryClient.services";
import {
	CollegeDelete,
	FABox,
	FAEditButton,
	SubmitButtonCollegeCard
} from "../components/CollegeDecisionModules/CollegeList.styles";
import React, { useState } from "react";
import { orderBy } from "lodash";
import { Modal } from "react-bootstrap";
import { Award, College } from "../types/College.types";
import { createFinancialAward, deleteFinancialAward, updateFinancialAward } from "../services/Award.services";
import { auth } from "../firebase";

export const CollegeEntry = (props: { college: College; setMsg: any }) => {
	const deleteQuery = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => deleteCollege(body),
		onSuccess: () => {
			props.setMsg("Deleted College Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const handleDeleteCollege = () => {
		deleteQuery.mutate({
			id: props.college.id
		});
	};

	/************************************ Field of Study ***********************************/
	const fosUpdate = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => updateCollege(body),
		onSuccess: () => {
			props.setMsg("Changed Field of Study Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const fieldOptions = props.college.fosList.map((p) => {
		return (
			<option id={`${p.iped}, ${p.major}`} value={p.major}>
				{p.major}
			</option>
		);
	});

	const handleFieldOfStudyModal = (title: string) => {
		const body = {
			uid: auth.currentUser?.uid,
			id: props.college.id,
			fos: title
		};

		fosUpdate.mutate(body);
	};
	/************************************ Field of Study ***********************************/

	/*********************************** Financial Award ***********************************/
	const [showFAList, setShowFAList] = useState(false);
	const [showFAEdit, setShowFAEdit] = useState(false);
	const [FAID, setFAID] = useState("");

	const [choice, setChoice] = useState("create");
	const [name, setName] = useState("");
	const [awardValue, setAwardValue] = useState(0);
	const [fourYear, setFourYear] = useState(false);

	const financialAwardCalc = () => {
		let totalAmount = 0;
		props.college.awards.map((financialAward) => (totalAmount += financialAward.amount));
		return totalAmount;
	};

	const createFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => createFinancialAward(body),
		onSuccess: () => {
			props.setMsg("Created Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const updateFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => updateFinancialAward(body),
		onSuccess: () => {
			props.setMsg("Updated Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const deleteFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => deleteFinancialAward(body),
		onSuccess: () => {
			props.setMsg("Deleted Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const handleCreateFA = () => {
		if (props.college.cost - awardValue > 0) {
			const body = {
				cid: props.college.id,
				name: name,
				amount: awardValue,
				perYear: fourYear
			};
			createFA.mutate(body);
			setShowFAEdit(false);
		} else {
			props.setMsg("Financial award cannot be more than total cost");
		}
	};

	const handleUpdateFA = () => {
		if (props.college.cost - awardValue > 0) {
			const body = {
				id: FAID,
				name: name,
				amount: awardValue,
				perYear: fourYear
			};
			updateFA.mutate(body);
			setShowFAEdit(false);
		} else {
			props.setMsg("Financial award cannot be more than total cost");
		}
	};

	const handleDeleteFA = (f: string) => {
		setFAID(f);
		const body = {
			id: f
		};

		deleteFA.mutate(body);
		setShowFAList(false);
	};

	const handleEditFA = (c: string, f?: Award) => {
		setChoice(c);

		if (f) {
			setFAID(f.id);
			setName(f.name);
			setAwardValue(f.amount);
			setFourYear(f.perYear);
		} else {
			setName("");
			setAwardValue(0);
			setFourYear(false);
		}

		setShowFAList(false);
		setShowFAEdit(true);
	};

	const listFA = orderBy(props.college.awards).map((FA) => {
		return (
			<FABox key={`{FA ${FA.id}}`}>
				<p>{FA.name}</p>
				<p>{currencyFormatter.format(FA.amount)}</p>
				<SubmitButtonCollegeCard
					onClick={() => {
						handleEditFA("update", FA);
					}}>
					Edit
				</SubmitButtonCollegeCard>
				<SubmitButtonCollegeCard
					onClick={() => {
						handleDeleteFA(FA.id);
					}}>
					Delete
				</SubmitButtonCollegeCard>
			</FABox>
		);
	});
	/*********************************** Financial Award ***********************************/

	return (
		<div id={`College Entry: ${props.college.id}`}>
			<h3>
				<b>{props.college.name}</b> <CollegeDelete onClick={() => handleDeleteCollege()}>x</CollegeDelete>
			</h3>
			<p style={{ display: "flex" }}>
				<span>Financial Award = {currencyFormatter.format(financialAwardCalc())}</span>
				<FAEditButton onClick={() => setShowFAList(true)}>Edit</FAEditButton>
			</p>
			<p>
				Field of Study:
				<select onChange={(value) => handleFieldOfStudyModal(value.target.value)} value={props.college.fos}>
					<option id={`${props.college.id}, UNDECIDED`}>Undecided</option>
					{props.college.fosList.length > 0 ? fieldOptions : <></>}
				</select>
			</p>

			{/*  MANAGE FINANCIAL AWARDS MODAL  */}
			<Modal
				show={showFAList}
				onHide={() => {
					setShowFAList(false);
				}}>
				<Modal.Header closeButton>
					<Modal.Title>Manage Financial Awards</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ display: "flex", justifyContent: "space-between", width: "47%" }}>
						<p>Name</p>
						<p>Amount</p>
					</div>
					{listFA}
				</Modal.Body>
				<Modal.Footer>
					<SubmitButtonCollegeCard
						onClick={() => {
							handleEditFA("create", undefined);
						}}>
						Add
					</SubmitButtonCollegeCard>
				</Modal.Footer>
			</Modal>

			{/*  ADD/UPDATE FINANCIAL AWARD MODAL  */}
			<Modal
				show={showFAEdit}
				onHide={() => {
					setShowFAEdit(false);
				}}>
				<Modal.Header closeButton>
					<Modal.Title>Input Financial Award</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Name:
					<input
						className="p-2 w-100"
						style={{ border: "1px solid black", borderRadius: "5px" }}
						type="text"
						placeholder={"Name"}
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					Amount:
					<input
						className="p-2 w-100"
						style={{ border: "1px solid black", borderRadius: "5px" }}
						type="number"
						placeholder={"Amount"}
						value={awardValue}
						onChange={(e) => {
							setAwardValue(e.target.valueAsNumber);
						}}
					/>
					Is this award received just once or every year?
					<select
						onChange={(e) => setFourYear(e.target.value === "true")}
						value={fourYear ? "true" : "false"}
						className={"p-2 w-100"}
						style={{ border: "1px solid black", borderRadius: "5px" }}>
						<option value={"false"}>One Time Award</option>
						<option value={"true"}>Recurring Award</option>
					</select>
				</Modal.Body>
				<Modal.Footer>
					<SubmitButtonCollegeCard
						onClick={() => {
							if (choice === "create") {
								handleCreateFA();
							} else {
								handleUpdateFA();
							}
						}}>
						Submit
					</SubmitButtonCollegeCard>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

import { altUser } from "../types/User.types";

export default function StudentListOrganizer(students: altUser[], organizeBy: string) {
	if (organizeBy === "first") {
		students.sort((a: altUser, b: altUser) => {
			if (a.firstName > b.firstName) {
				return 1;
			} else if (a.firstName < b.firstName) {
				return -1;
			} else {
				return 0;
			}
		});
	} else if (organizeBy === "last") {
		students.sort((a: altUser, b: altUser) => {
			if (a.lastName > b.lastName) {
				return 1;
			} else if (a.lastName < b.lastName) {
				return -1;
			} else {
				return 0;
			}
		});
	} else if (organizeBy === "email") {
		students.sort((a: altUser, b: altUser) => {
			if (a.email > b.email) {
				return 1;
			} else if (a.email < b.email) {
				return -1;
			} else {
				return 0;
			}
		});
	}

	return students;
}

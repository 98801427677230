import styled from "styled-components";
import { device } from "../../util/device";

export const CostNavMaster = styled.div`
	width: 90%;
	margin-left: 2vw;
	margin-top: 10px;

	h1 {
		button {
			font-size: medium;
			margin-top: 10px;
		}

		display: flex;
	}

	h2 {
		text-align: center;
		font-weight: bold;
	}
`;

export const CostNavTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
`;

export const CostSearchBar = styled.div`
	border-radius: 5px;
	border: black 1px solid;
	width: 100%;
	height: 50px;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 25px;

	input {
		width: 100%;
		outline: none;
	}
`;

export const CostSearchBox = styled.div`
	position: absolute;
	background-color: white;
	border-radius: 10px;
	width: 66%;
	opacity: 90%;
	z-index: 2;
`;

export const CostSearchItem = styled.div`
	text-align: center;
	line-height: 50px;
	margin-top: 5px;
	padding: 5px;
	cursor: pointer;
	min-height: 50px;
	:hover {
		background: #cccccc;
	}
`;

export const CardSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

export const Card = styled.div`
	height: 390px;
	width: 22%;
	background-color: #f1f1f1;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 45px;
	text-align: center;
	align-items: center;
	justify-content: center;

	p {
		padding-top: 15px;
	}
`;

export const CardHeader = styled.div`
	height: 100px;
	width: 100%;
	background-color: var(--croi-green);
	color: black;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	font-weight: bold;
	font-size: 18px;
`;

export const CardButton = styled.button`
	height: 40px;
	width: 80%;
	color: black;
	background-color: var(--croi-green);
	margin-top: 15px;
	margin-bottom: 15px;
	border-radius: 10px;
`;

export const CostNavMid = styled.div`
	display: flex;

	@media only screen and ${device.mobileM} {
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;
	}
`;

export const CostDataBoxDiv = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;
	}

	@media only screen and ${device.tablet} {
		width: 23%;
	}
`;

export const CostDataBox = styled.div`
	border-radius: 5px;
	background: #eeeeee;
	width: 100%;

	h5 {
		padding: 10px;
		border-radius: 5px 5px 0 0;
		background: #d9d9d9;
	}

	h6 {
		font-weight: bold;
		font-size: 20px;
		text-align: center;
		margin-bottom: 0;
	}

	select {
		margin-left: auto;
		margin-right: auto;
		display: block;
		margin-bottom: 15px;
		width: 80%;
		background: white;
		padding: 5px;
		border-radius: 5px;
		border: black 1px solid;
	}

	p {
		font-size: 24px;
		color: var(--croi-green);
		font-weight: bold;
		text-align: center;
	}
`;

export const YearlyTotal = styled.div`
	margin-left: 20px;
	margin-top: 20px;

	h6 {
		font-weight: bolder;
		font-size: 20px;
		margin-bottom: 0;
	}

	p {
		color: green;
		font-weight: bolder;
		font-size: 30px;
	}
`;

export const EstimatedTotal = styled.div`
	background-color: var(--croi-green);
	height: 120px;
	color: white;
	font-weight: bold;
	text-align: right;
	padding-right: 40px;
	border-radius: 10px;

	h6 {
		margin-bottom: 0;
		font-weight: bold;
		padding-top: 20px;
		font-size: 17px;
	}

	p {
		font-size: 42px;
		font-weight: bolder;
	}
`;

export const AddAwardButton = styled.button`
	color: var(--croi-green);
	text-decoration: underline;
	background-color: white;
`;

export const MinimizeButton = styled.button`
	background-color: var(--croi-green);
	color: white;
	border-radius: 10px;
	width: 100px;
	height: 30px;
	font-weight: bold;
`;

export const AwardStyle = styled.div`
	border-radius: 15px;
	background-color: var(--croi-green);
	color: white;
	display: flex;
	cursor: pointer;
	margin-left: 10px;
	margin-bottom: 10px;

	p {
		padding: 10px;
		height: 28px;
	}

	button {
		border-radius: 50%;
		border: 3px solid white;
		background-color: var(--croi-green);
		color: white;
		margin-right: 10px;
		height: 30px;
		width: 30px;
		padding-top: 2px;
		margin-left: 5px;
		margin-top: 6px;
	}
`;

export const DeleteCollegeButton = styled.button`
	border-radius: 50%;
	border: 3px solid white;
	background-color: var(--croi-green);
	color: white;
	height: 30px;
	width: 30px;
	position: absolute;
	margin-left: 13%;
	margin-bottom: 3%;
`;

import styled from "styled-components";
import { device } from "../../util/device";

export const FooterMain = styled.div`
	background-color: white;
	color: var(--croi-green);
	width: 85%;
	margin-left: 7%;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: row;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
	}
`;

export const FooterLeft = styled.div`
	position: absolute;
	flex-direction: row;
	display: flex;

	h2 {
		font-size: 60px;
		font-family: Aleo, serif;
		font-weight: 700;
	}

	a {
		margin-top: 10px;
		font-size: 20px;
		text-decoration: none;
		color: white;
	}

	@media only screen and ${device.mobileM} {
		margin-left: 10px;
		margin-top: 5px;
	}

	@media only screen and ${device.tablet} {
		margin-left: 50px;
		margin-top: 20px;
	}
`;

export const FooterMiddle = styled.div`
	display: flex;
	text-align: center;
	flex-wrap: wrap;

	@media only screen and ${device.mobileM} {
		margin-top: 75px;

		a {
			font-size: 18px;
			text-decoration: none;
			color: var(--croi-green);
			margin-left: 10px;
		}
	}

	@media only screen and ${device.tablet} {
		margin-left: 54%;
		margin-top: 50px;

		a {
			font-size: 20px;
			text-decoration: none;
			color: var(--croi-green);
			margin-left: 30px;
		}
	}
`;

export const LinkImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center no-repeat;
	width: 60px;
	height: 60px;
`;

interface ImageProps {
	imageUrl: string;
}

import React, { useState } from "react";
import { AreaOfChange, ChangePictureLabel, LeftSide, ProfileCircle, SettingsWrapper } from "./UserSettings.styles";
import { useQuery } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { auth } from "../../firebase";
import { Sponsor } from "../../types/Sponsor.types";
import { fetchSubscriptionDetails } from "../../services/Stripe.services";
import { currencyFormatter } from "../../util/currencyFormatter";
import SettingsWindow from "../../components/SettingsWindow/SettingsWindow";
import { Snack } from "../../atoms/SnackBarAlert";
import { supabase } from "../../supabase";

export default function UserSettings(props: { user: User; sponsor: Sponsor | undefined }) {
	const [windowOpen, setWindow] = useState(false);
	const [windowType, setWindowType] = useState("");
	const [snackMsg, setMsg] = useState("");
	const user = auth.currentUser;

	const getSubscription = useQuery({
		queryKey: ["subscription"],
		queryFn: () => fetchSubscriptionDetails(),
		enabled: props.user.paymentType == "Individual"
	});
	//*************************************** Change User Image **************************************//
	const [link, setLink] = useState<string>(user?.photoURL ? user.photoURL : "");

	const changeImgHandler = async (input: File) => {
		if (user) {
			const fileName = `userProfile/${props.user.firstName}${props.user.lastName}${input.name.slice(
				input.name.lastIndexOf(".")
			)}`;

			const { data, error } = await supabase.storage.from("pictures").upload(fileName, input, {
				cacheControl: "3600",
				upsert: true
			});

			if (error) {
				setMsg("Image Upload Failed");
			} else if (data) {
				setLink(data.fullPath);
			}
		}
	};
	//*************************************** Change User Image **************************************//

	return (
		<>
			{windowOpen ? (
				<SettingsWindow type={windowType} setWindow={setWindow} user={props.user} setMsg={setMsg} />
			) : (
				<></>
			)}
			<Snack msg={snackMsg} setMsg={setMsg} />
			<SettingsWrapper>
				<h1>My Settings</h1>
				<AreaOfChange>
					<LeftSide>
						<ProfileCircle imageUrl={link} />
						<ChangePictureLabel htmlFor={"picture"} onClick={() => document.getElementById("picture")!.click()}>
							Change Profile Picture
							<input
								id={"picture"}
								name={"picture"}
								type={"file"}
								accept="image/*"
								onChange={(e) => {
									const files = e.target.files;

									if (files && files.length > 0) {
										changeImgHandler(files[0]);
									}
								}}
							/>
						</ChangePictureLabel>
					</LeftSide>
					<div>
						<h2>
							{props.user.firstName} {props.user.lastName}
						</h2>
						<p>{props.sponsor ? props.sponsor.name : ""}</p>
						<p>{props.user.email}</p>
						<p>
							{props.user.state ? props.user.state : "No State"} - {props.user.zip ? props.user.zip : "No Zip"}
						</p>
						<button
							onClick={() => {
								setMsg("Click!");
								setWindowType("EDIT");
								setWindow(true);
							}}>
							Edit Info
						</button>
						<br />
						<button
							onClick={() => {
								setWindowType("PASSWORD");
								setWindow(true);
							}}>
							Change Password
						</button>
					</div>
				</AreaOfChange>
				{props.user.paymentType == "Free" ? (
					<AreaOfChange>
						<LeftSide>
							<h2>Croi Free Plan</h2>
							<button
								onClick={() => {
									setWindowType("UPGRADE");
									setWindow(true);
								}}>
								Upgrade Plan
							</button>
							<br />
							<button style={{ color: "red" }}>Delete Account</button>
						</LeftSide>
						<div>
							<h4>Billing</h4>
							<p>Account Created: {props.user.creationDate.toString().slice(0, 10)}</p>
							<p>Next Billing Date: N/A</p>
							<p>Amount: $0</p>
						</div>
					</AreaOfChange>
				) : props.user.paymentType == "Individual" ? (
					<AreaOfChange>
						<LeftSide>
							<h2>Croi Annual Plan</h2>
							<a href={"https://billing.stripe.com/p/login/6oEbLx7YF3VOb283cc"} target={"_blank"}>
								Change Plan/Payment Info
							</a>
							<br />
							<button
								style={{ color: "red" }}
								onClick={() => {
									setWindowType("DELETE");
									setWindow(true);
								}}>
								Delete Account
							</button>
						</LeftSide>
						<div>
							<h4>Billing</h4>
							<p>Account Created: {props.user.creationDate.toString().slice(0, 10)}</p>
							<p>Next Billing Date: {getSubscription.data ? getSubscription.data.nextBill : "Error"}</p>
							<p>Amount: {getSubscription.data ? currencyFormatter.format(getSubscription.data.price) : "Error"}</p>
						</div>
					</AreaOfChange>
				) : (
					<></>
				)}
			</SettingsWrapper>
		</>
	);
}

import styled from "styled-components";
import { device } from "../../util/device";

export const ContactBox = styled.div`
	background-color: #eeeeee;
	border-radius: 10px;
	text-align: center;

	margin-bottom: 5%;
	margin-top: 60px;

	@media only screen and ${device.mobileS} {
		display: flex;
		flex-direction: column;
		width: 90%;
		margin-left: 5%;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		height: 525px;
		width: 70%;
		margin-left: 15%;
	}
`;

export const PricingColumn = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		font-weight: 800;
	}

	h3 {
		margin-top: -10px;
	}

	@media only screen and ${device.mobileS} {
		width: 100%;
		text-align: left;
		margin-top: 15px;
	}

	@media only screen and ${device.tablet} {
		width: 60%;
		text-align: left;
		margin-top: 90px;
	}
`;

export const InfoColumn = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		font-weight: 800;
	}

	@media only screen and ${device.mobileS} {
		width: 100%;
		margin-left: 2%;
		text-align: center;
		margin-top: 15px;
	}

	@media only screen and ${device.tablet} {
		width: 60%;
		margin-left: 2%;
		text-align: left;
		margin-top: 15px;
	}
`;

export const InputSection = styled.div`
	display: flex;
	flex-direction: column;

	input {
		width: 90%;
		margin-left: 2%;
		height: 55px;
		margin-bottom: 30px;
		border-radius: 5px;
		padding-left: 15px;
		color: black;
	}

	select {
		width: 90%;
		margin-left: 2%;
		height: 55px;
		margin-bottom: 30px;
		border-radius: 5px;
		padding-left: 15px;
		color: black;
	}
`;

export const NameInputSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 92%;
`;

export const PricingBoxes = styled.div`
	@media only screen and ${device.mobileM} {
		height: 120px;
	}

	@media only screen and ${device.tablet} {
		height: 75px;
	}

	margin-right: auto;
	margin-left: auto;
	display: flex;
	width: 95%;
	margin-bottom: 30px;
	border-radius: 5px;
	color: black;
	background-color: white;
	justify-content: left;
	align-items: center;

	input {
		width: 25px;
		height: 25px;
		border-radius: 30px;
		margin-left: 15px;
		margin-right: 15px;
		border: black solid 1px;
		accent-color: var(--croi-green);
	}
`;

export const PriceBoxRow = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;

	@media only screen and ${device.mobileM} {
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;
	}
`;

export const StackedWords = styled.div`
	display: flex;
	flex-direction: column;

	h4 {
		font-size: 20px;
		font-weight: 800;
		margin-bottom: -5px;
	}

	p {
		margin-left: 1px;
		margin-bottom: 0;
	}

	i {
		font-size: 14px;
		margin-top: 0;
	}
`;

export const PaymentButton = styled.button`
	width: 90%;
	height: 40px;
	border-radius: 8px;
	background-color: var(--croi-green);
	color: white;
	margin: 25px auto 10px;
`;

export const SpecialCard = styled.div`
	border: 1px solid lightgray;
	border-radius: 10px;
	background: white;
	text-align: center;
	height: 230px;
	position: relative;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;

	@media only screen and ${device.mobileM} {
		width: 90%;
	}

	@media only screen and ${device.tablet} {
		width: 40%;
	}

	input {
		margin: 5px auto 15px;
		display: block;
		width: 25px;
		height: 25px;
		accent-color: var(--croi-green);
	}

	h4 {
		font-weight: bold;
		margin-top: 15px;
	}

	p {
		margin-bottom: 0;
	}

	h5 {
		background-color: var(--croi-green);
		font-weight: bold;
		height: 35px;
		width: 100%;
		line-height: 35px;
		border-radius: 10px;
		position: absolute;
		bottom: 0;
		margin-bottom: 0;
	}
`;

export default function HowToButton(props: { text: string; link: string }) {
	return (
		<button
			style={{
				width: "120px",
				height: "40px",
				backgroundColor: "var(--croi-green)",
				color: "white",
				fontWeight: "bold",
				borderRadius: "15px",
				marginRight: "auto",
				marginLeft: "25px",
				display: "block"
			}}
			onClick={() => window.open(props.link)}>
			{props.text}
		</button>
	);
}

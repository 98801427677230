import React, { useState } from "react";
import {
	CheckboxDiv,
	FAMaster,
	FinancialRightSide,
	LocationSection,
	LockCollege,
	MatchBoxColumn,
	MatchBoxList,
	MatchCollegeBox,
	MatchEdit,
	MatchMeButton,
	MatchProfileCircle,
	NameButtonSection,
	PricingInfo,
	SpecificPricing,
	TopSection,
	WindowLeftSide,
	WindowRightSide
} from "./FinancialAidMatch.styles";
import { currencyFormatter } from "../../../util/currencyFormatter";
import { useMutation } from "@tanstack/react-query";
import { createCollege, fetchMatchData } from "../../../services/College.services";
import { queryClient } from "../../../services/QueryClient.services";
import FinancialProfileWindow from "../../FinancialProfileWindow/FinancialProfileWindow";
import { College } from "../../../types/College.types";
import { User } from "../../../types/User.types";
import ToolTip from "../../../atoms/ToolTip";
import { MatchData, MatchListData } from "../../../types/MatchData.types";
import { Snack } from "../../../atoms/SnackBarAlert";
import { ScaleLoader } from "react-spinners";
import { auth } from "../../../firebase";
import Select, { MultiValue } from "react-select";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SettingsWindow from "../../SettingsWindow/SettingsWindow";

interface valueLabel {
	value: string;
	label: string;
}

const fosChoices: valueLabel[] = [
	{
		value: "Art",
		label: "Art"
	},
	{
		value: "Business",
		label: "Business"
	},
	{
		value: "Communications",
		label: "Communications"
	},
	{
		value: "Computer Science",
		label: "Computer Science"
	},
	{
		value: "Education",
		label: "Education"
	},
	{
		value: "Engineering",
		label: "Engineering"
	},
	{
		value: "Health Science",
		label: "Health Science"
	},
	{
		value: "Humanities",
		label: "Humanities"
	},
	{
		value: "Math",
		label: "Math"
	},
	{
		value: "Physical & Life Science",
		label: "Physical & Life Science"
	},
	{
		value: "Social Science",
		label: "Social Science"
	}
];

const envChoices: valueLabel[] = [
	{
		value: "rural",
		label: "rural"
	},
	{
		value: "online",
		label: "online"
	},
	{
		value: "suburban",
		label: "suburban"
	},
	{
		value: "urban",
		label: "urban"
	}
];

export default function FinancialAidMatch(props: { colleges: College[]; user: User }) {
	const [faWindow, setFAWindow] = useState<"Profile" | "None">("None");
	const [matchList, setMatchList] = useState<MatchData>({ reach: [], safety: [], target: [] });
	const [snackMsg, setMsg] = useState("");
	const [type, setType] = useState("");
	const [religion, setReligion] = useState("");
	const [environment, setEnvironment] = useState<string[]>([]);
	const [size, setSize] = useState("");
	const [fos, setFOS] = useState<string[]>([]);
	const [dist, setDist] = useState(0);
	const [zip, setZip] = useState("");
	const [checkFinances, setCheck] = useState(false);
	const [numView, setView] = useState(3);
	const [sat, setSAT] = useState(0);
	const [upgradeWindow, setWindow] = useState(false);

	const collegeCreation = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => createCollege(body),
		onSuccess: (res) => {
			setMsg(res);
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const addCollege = (collegeId: string) => {
		collegeCreation.mutate({ uid: props.user.id, iped: collegeId });
	};

	const completion = () => {
		const max = 9;
		let numerator = 0;

		numerator += props.user.familySize > -1 ? 1 : 0;
		numerator += props.user.numParents > -1 ? 1 : 0;
		numerator += props.user.parentAssets > -1 ? 1 : 0;
		numerator += props.user.studentIncome > -1 ? 1 : 0;
		numerator += props.user.taxPaid > -1 ? 1 : 0;
		numerator += props.user.parentIncome > -1 ? 1 : 0;
		numerator += props.user.untaxedIncome > -1 ? 1 : 0;
		numerator += props.user.studentAssets > -1 ? 1 : 0;
		numerator += props.user.oldestParent > -1 ? 1 : 0;

		return (numerator / max) * 100;
	};

	const editList = (college: MatchListData, type: "target" | "reach" | "safety") => {
		if (type === "safety") {
			const temp = matchList.safety.find((col) => col.college.iped === college.college.iped)!;
			matchList.safety.splice(matchList.safety.indexOf(temp), 1);
		} else if (type === "reach") {
			const temp = matchList.reach.find((col) => col.college.iped === college.college.iped)!;
			matchList.reach.splice(matchList.reach.indexOf(temp), 1);
		} else if (type === "target") {
			const temp = matchList.target.find((col) => col.college.iped === college.college.iped)!;
			matchList.target.splice(matchList.target.indexOf(temp), 1);
		}
	};

	const matchGetter = useMutation({
		mutationKey: ["Match"],
		mutationFn: (body: any) => fetchMatchData(body),
		onSuccess: (response) => {
			if (response.target.length + response.reach.length + response.safety.length == 0) {
				setMsg("No colleges were returned, maybe try searching with other parameters.");
			} else {
				setMsg(`Success! Returned ${response.target.length + response.reach.length + response.safety.length} colleges`);
			}
			setMatchList(response);
			window.scrollBy(0, 570 - window.scrollY);
		}
	});

	const matcher = async () => {
		if (sat < 850 || sat > 1600) {
			setMsg("Please enter your actual or estimated SAT score to ensure accurate matching");
			return;
		}

		const body = {
			finances: checkFinances,
			fos: fos.length > 0 ? fos : undefined,
			environment: environment.length > 0 ? environment : undefined,
			size: size.length > 0 ? size : undefined,
			type: type.length > 0 ? type : undefined,
			religiousAffiliation: religion.length > 0 ? religion : undefined,
			distance: dist > 0 ? dist : undefined,
			zip: zip.length > 0 ? zip : undefined,
			uid: auth.currentUser?.uid,
			sat: sat
		};

		matchGetter.mutate(body);
	};

	const getFOSList = () => {
		const list: valueLabel[] = [];

		for (const item of fos) {
			list.push({
				value: item,
				label: item
			});
		}

		return list;
	};

	const fosSetter = (vals: MultiValue<valueLabel>) => {
		const temp: string[] = [];

		for (const value of vals) {
			temp.push(value.value);
		}

		setFOS(temp);
	};

	const getEnvList = () => {
		const list: valueLabel[] = [];

		for (const item of environment) {
			list.push({
				value: item,
				label: item
			});
		}

		return list;
	};

	const envSetter = (vals: MultiValue<valueLabel>) => {
		const temp: string[] = [];

		for (const value of vals) {
			temp.push(value.value);
		}

		setEnvironment(temp);
	};

	const matchBox = (college: MatchListData, type: "target" | "reach" | "safety") => {
		return (
			<MatchCollegeBox>
				<LockCollege
					style={{
						display: college.college.iped == "-1" ? "block" : "none"
					}}
					onClick={() => setWindow(true)}>
					<FontAwesomeIcon
						icon={faLock}
						size={"5x"}
						style={{
							marginLeft: "auto",
							marginRight: "auto",
							marginTop: "20%",
							display: "block"
						}}
					/>
				</LockCollege>
				<NameButtonSection>
					<h4 data-fulltext={college.college.name}>{college.college.name}</h4>
					<img
						onClick={() => {
							addCollege(college.college.iped);
							if (!(props.colleges.length >= 2 && props.user.paymentType === "Free")) {
								editList(college, type);
							}
						}}
						style={{ cursor: "pointer" }}
						src="/images/plus_button.png"
						alt="plusButton"
					/>
				</NameButtonSection>
				<LocationSection>
					<img src="/images/location_black.png" alt="locationBlack" />
					<h6>
						{college.college.city}, {college.college.state}
					</h6>
					<i>{college.fieldOfStudy ? college.fieldOfStudy.major : ""}</i>
				</LocationSection>
				<hr />
				<PricingInfo>
					<SpecificPricing style={{ paddingTop: "10px" }}>
						<ToolTip
							text={
								"The total cost of attendance per year, including tuition, fees," +
								" room, and board, before any financial aid is applied."
							}
						/>
						&nbsp;
						<p>Sticker Price (Year 1):</p>
						<b>
							{currencyFormatter.format(
								college.college.tuition +
									college.college.otherFees +
									college.college.booksNSupplies +
									college.college.onCampusLiving +
									college.college.mealPlanCost
							)}
						</b>
					</SpecificPricing>
					<SpecificPricing>
						<ToolTip
							text={
								"The average amount of grants and scholarships a student receives," +
								" reducing the sticker price to the net cost."
							}
						/>
						&nbsp;
						<p> Average Aid: </p>
						<b>{currencyFormatter.format(college.college.avgAid)}</b>
					</SpecificPricing>
					<SpecificPricing>
						<ToolTip text={"The salary expected directly out of college"} />
						&nbsp;
						<p> Early Pay: </p>
						<b>
							{currencyFormatter.format(
								college.fieldOfStudy ? college.fieldOfStudy.earlyPay : college.college.earlyPay
							)}
						</b>
					</SpecificPricing>
					<SpecificPricing>
						<ToolTip text={"The salary expected halfway through your career"} />
						&nbsp;
						<p> Mid Pay: </p>
						<b>
							{currencyFormatter.format(college.fieldOfStudy ? college.fieldOfStudy.midPay : college.college.midPay)}
						</b>
					</SpecificPricing>
				</PricingInfo>
			</MatchCollegeBox>
		);
	};

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			{upgradeWindow ? (
				<SettingsWindow type={"UPGRADE"} setWindow={setWindow} user={props.user} setMsg={setMsg} />
			) : (
				<></>
			)}
			<FAMaster>
				<FinancialProfileWindow user={props.user} state={faWindow} setState={setFAWindow} setMsg={setMsg} />
				<h4 style={{ textAlign: "center" }}>My Criteria</h4>
				<i style={{ textAlign: "center", display: "block" }}>
					Enter your intended field of study, school preferences, family finances, and test scores to tailor your
					college matches to your specific needs and goals.
				</i>
				<TopSection>
					<WindowLeftSide>
						<h3>School Criteria</h3>
						<p>
							<i>Field of Study:</i>
							<Select
								value={getFOSList()}
								onChange={(val) => fosSetter(val)}
								getOptionLabel={(val: valueLabel) => val.label}
								getOptionValue={(val: valueLabel) => val.value}
								options={fosChoices}
								isClearable={true}
								backspaceRemovesValue={true}
								isMulti={true}
								className={"select-container"}
								placeholder={"No Preference"}
							/>
						</p>
						<p>
							<i>Environment:</i>
							<Select
								value={getEnvList()}
								onChange={(val) => envSetter(val)}
								getOptionLabel={(val: valueLabel) => val.label}
								getOptionValue={(val: valueLabel) => val.value}
								options={envChoices}
								isClearable={true}
								backspaceRemovesValue={true}
								isMulti={true}
								className={"select-container"}
								placeholder={"No Preference"}
							/>
						</p>
						<p>
							<i>Size:</i>
							<select onChange={(e) => setSize(e.target.value)}>
								<option value={""}>No Preference</option>
								<option>Small (&lt;5,000)</option>
								<option>Medium (5,000-15,000)</option>
								<option>Large (&gt;15,000)</option>
							</select>
						</p>
						<p>
							<i>School Type:</i>
							<select onChange={(e) => setType(e.target.value)}>
								<option value={""}>No Preference</option>
								<option>Public</option>
								<option>Private</option>
							</select>
						</p>
						<p>
							<i>Religious Affiliation:</i>
							<select onChange={(e) => setReligion(e.target.value)}>
								<option value={""}>No Preference</option>
								<option>Catholic</option>
								<option>Protestant</option>
								<option>Jewish</option>
							</select>
						</p>
					</WindowLeftSide>
					<WindowRightSide>
						<h3 style={{ marginBottom: "0" }}>Finances</h3>
						<i style={{ textAlign: "center", fontSize: "15px" }}>(Financial information is completely optional)</i>
						<FinancialRightSide>
							<b>Financial Aid Profile</b> &nbsp; <MatchEdit onClick={() => setFAWindow("Profile")}>Edit</MatchEdit>
							&nbsp;
							{completion() >= 100 ? (
								<span>
									Complete&nbsp;
									<MatchProfileCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</MatchProfileCircle>
								</span>
							) : (
								<span>
									Incomplete&nbsp;<MatchProfileCircle style={{ backgroundColor: "red" }}>X</MatchProfileCircle>
								</span>
							)}
						</FinancialRightSide>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<div style={{ marginTop: "2px" }}>
								<ToolTip text={"Includes your financial details when matching schools to personalize your results."} />
							</div>
							<i>Consider Finances in Results:&nbsp;</i>
							<CheckboxDiv>
								<input type={"checkbox"} onChange={() => setCheck(!checkFinances)} />
							</CheckboxDiv>
						</div>
						<br />
						<div
							style={{
								display: "flex",
								justifyContent: "space-evenly",
								flexDirection: window.outerWidth <= 500 ? "column" : "row"
							}}>
							<div style={{ width: window.outerWidth <= 500 ? "100%" : "50%" }}>
								<h3>Academic Record</h3>
								<i>SAT Score</i>
								<br />
								<input type={"number"} onChange={(e) => setSAT(e.target.valueAsNumber)} />
							</div>
							<div style={{ width: window.outerWidth <= 500 ? "100%" : "50%" }}>
								<h3>Distance</h3>
								<i>Home Zipcode</i>
								<br />
								<input type={"text"} onChange={(e) => setZip(e.target.value)} />
								<br />
								<i>Max Distance From Home (Miles)</i>
								<br />
								<select onChange={(e) => setDist(parseInt(e.target.value))} style={{ width: "40%" }}>
									<option value={-1}>No Preference</option>
									<option value={100}>&lt;100</option>
									<option value={500}>100-500</option>
									<option value={1000}>500-1,000</option>
									<option value={2000}>1,000-2,000</option>
								</select>
							</div>
						</div>
					</WindowRightSide>
				</TopSection>
				<div style={{ width: "95%" }}>
					{matchGetter.isLoading ? (
						<MatchMeButton>
							<ScaleLoader color="black" loading={matchGetter.isLoading} />
						</MatchMeButton>
					) : (
						<MatchMeButton onClick={() => matcher()}>Match Me&nbsp;</MatchMeButton>
					)}
				</div>
				{/*END OF MATCH*/}

				<hr className={"searchBreak"} />
				<h4 style={{ textAlign: "center", width: "94%", marginBottom: "0" }}>Match Results</h4>
				<i style={{ textAlign: "center", width: "94%", display: "block", marginBottom: "15px" }}>
					If your filters are too restrictive, some columns for reach, target, or safety schools may appear empty.
				</i>
				{matchList.target.length + matchList.reach.length + matchList.safety.length < 1 ? (
					<p style={{ textAlign: "center" }}>
						Not Complete - Select "Match Me" to find college recommendations based on your criteria
					</p>
				) : (
					<MatchBoxList>
						<MatchBoxColumn>
							<h4 style={{ textAlign: "center", fontStyle: "italic" }}>SAFETY</h4>
							{matchList.safety.slice(0, numView).map((college) => matchBox(college, "safety"))}
						</MatchBoxColumn>
						<MatchBoxColumn style={{ backgroundColor: "var(--croi-green)", borderRadius: "10px" }}>
							<h4 style={{ textAlign: "center", fontStyle: "italic" }}>TARGET</h4>
							{matchList.target.slice(0, numView).map((college) => matchBox(college, "target"))}
						</MatchBoxColumn>
						<MatchBoxColumn>
							<h4 style={{ textAlign: "center", fontStyle: "italic" }}>REACH</h4>
							{matchList.reach.slice(0, numView).map((college) => matchBox(college, "reach"))}
						</MatchBoxColumn>
					</MatchBoxList>
				)}
				<div style={{ width: "95%" }}>
					<button
						onClick={() => {
							if (props.user.paymentType === "Free") {
								setWindow(true);
							} else {
								setView(numView == 3 ? 12 : 3);
							}
						}}
						style={{
							marginLeft: "auto",
							marginRight: "auto",
							display:
								matchList.target.length + matchList.reach.length + matchList.safety.length > 0 ? "block" : "none",
							color: "white",
							backgroundColor: "var(--croi-green)",
							width: "160px",
							height: "60px",
							borderRadius: "10px",
							marginTop: "10px",
							fontSize: "20px",
							fontWeight: "bold"
						}}>
						{numView == 3 ? "View More " : "View Less "}
						{props.user.paymentType === "Free" ? (
							<img
								src={"/images/CroiPremium.svg"}
								alt={"Croi Premium Logo"}
								style={{
									width: "14%"
								}}
							/>
						) : (
							<></>
						)}
					</button>
				</div>
				<hr className={"searchBreak"} />
				<h4 style={{ textAlign: "center" }}>My College List</h4>
				<MatchBoxList>
					{props.colleges.map((college) => (
						<MatchCollegeBox style={{ width: window.outerWidth <= 500 ? "100%" : "32%" }}>
							<NameButtonSection>
								<h4 data-fulltext={college.name}>{college.name}</h4>
							</NameButtonSection>
							<LocationSection>
								<img src="/images/location_black.png" alt="locationBlack" />
								<h6>
									{college.city}, {college.state}
								</h6>
							</LocationSection>
							<hr />
							<PricingInfo>
								<SpecificPricing style={{ paddingTop: "10px" }}>
									<ToolTip
										text={
											"The total cost of attendance per year, including tuition, fees," +
											" room, and board, before any financial aid is applied."
										}
									/>
									&nbsp;
									<p>Sticker Price (Year 1):</p>
									<b>{currencyFormatter.format(college.costOverTime[0])}</b>
								</SpecificPricing>
								<SpecificPricing>
									<ToolTip
										text={
											"The average amount of grants and scholarships a student receives," +
											" reducing the sticker price to the net cost."
										}
									/>
									&nbsp;
									<p> Average Aid: </p>
									<b>{currencyFormatter.format(college.avgAid)}</b>
								</SpecificPricing>
								<SpecificPricing>
									<ToolTip text={"The salary expected directly out of college"} />
									&nbsp;
									<p> Early Pay: </p>
									<b>{currencyFormatter.format(college.earlyPay)}</b>
								</SpecificPricing>
								<SpecificPricing>
									<ToolTip text={"The salary expected halfway through your career"} />
									&nbsp;
									<p> Mid Pay: </p>
									<b>{currencyFormatter.format(college.midPay)}</b>
								</SpecificPricing>
							</PricingInfo>
						</MatchCollegeBox>
					))}
				</MatchBoxList>
			</FAMaster>
		</>
	);
}

import React from "react";
import { FooterLeft, FooterMain, FooterMiddle, LinkImage } from "./Footer.styles";

export default function Footer() {
	return (
		<>
			<FooterMain>
				<FooterLeft>
					<h2>croi</h2>
					<div style={{ display: "flex", justifyContent: "space-evenly", marginLeft: "20px" }}>
						<a href={"https://instagram.com/croi.io?igshid=OGQ5ZDc2ODk2ZA=="}>
							<LinkImage imageUrl={"images/instaGreen.png"} />
						</a>
						<a href={"https://www.linkedin.com/company/croi-inc/"}>
							<LinkImage imageUrl={"images/linkedInGreen.png"} />
						</a>
						<a href={"https://www.youtube.com/@croiinc.5287"}>
							<LinkImage imageUrl={"images/youtubeGreen.png"} />
						</a>
					</div>
				</FooterLeft>

				<FooterMiddle>
					<a href={"/about"}>About</a>
					{/*<a href={"/pricing"}>Pricing</a>*/}
					{/*<a href="/counselors">Counselors</a>*/}
					<a href={"/blog"}>Blog</a>
					<a href="/contact">Contact</a>
					<a href="/login">Sign In</a>
					<a href="/signup">Sign Up</a>
				</FooterMiddle>
			</FooterMain>
			<hr style={{ borderWidth: "3px", width: "80%", marginLeft: "10%" }}></hr>
			<p style={{ width: "80%", marginLeft: "10%" }}>©2023 CROI, Inc. All Rights Reserved</p>
		</>
	);
}

import React, { useState } from "react";
import {
	Card,
	CardButton,
	CardHeader,
	CardSection,
	CurrentCollege,
	DeleteCollegeButton,
	SalaryMaster,
	SalarySearch,
	SalarySearchBar,
	SalarySearchBox,
	SalarySearchItem,
	SchoolInfo,
	SelectButton
} from "./SalaryInsights.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ScaleLoader } from "react-spinners";
import useFetch from "../../hooks/useFetch";
import ToolTip from "../../atoms/ToolTip";
import { College, fetchUniqueReturn } from "../../types/College.types";
import { currencyFormatter } from "../../util/currencyFormatter";
import { createCollege, deleteCollege, fetchUniqueCollege, updateCollege } from "../../services/College.services";
import { queryClient } from "../../services/QueryClient.services";
import { Snack } from "../../atoms/SnackBarAlert";

export default function SalaryInsights(props: { colleges: College[] }) {
	const { searchResults, setSearchResults } = useFetch();
	const [currentSchool, setCurrentSchool] = useState<string>("");
	const [snackMsg, setMsg] = useState("");
	const [newCollegeEnabler, setEnabler] = useState(false);
	const [fetchID, setFetchID] = useState("");

	const deleteQuery = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => deleteCollege(body),
		onSuccess: () => {
			setMsg("Deleted College Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const getSalaryInfo = useQuery<fetchUniqueReturn, Error>({
		queryKey: ["salaryInfo", fetchID],
		queryFn: () => fetchUniqueCollege(fetchID),
		refetchInterval: false,
		enabled: newCollegeEnabler,
		retry: false
	});

	const collegeUpdate = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => updateCollege(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["colleges"]);
		}
	});

	const collegeCreation = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => createCollege(body),
		onSuccess: () => {
			setMsg("Created college successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const handleItemClick = (collegeId: string) => {
		setSearchResults({ ...searchResults, slug: "" });
		setFetchID(collegeId);
		setEnabler(true);
	};

	const handleSelect = (major: string) => {
		const col = props.colleges.find((c) => c.iped == getSalaryInfo.data!.uniqueCollege.iped);
		if (col) {
			setMsg("Updating college salary...");
			collegeUpdate.mutate({
				id: col.id,
				fos: major
			});
		} else {
			setMsg(`Add ${getSalaryInfo.data!.uniqueCollege.name} to your list to set a field of study.`);
		}
	};

	const getMajor = () => {
		if (getSalaryInfo.data) {
			const col = props.colleges.find((c) => c.iped == getSalaryInfo.data.uniqueCollege.iped);

			if (col) {
				return col.fos;
			}
		}

		return "";
	};

	const displayCollege = () => {
		if (getSalaryInfo.data) {
			if (getSalaryInfo.data.fields.length > 0) {
				return (
					<CurrentCollege>
						<SchoolInfo>
							<h3>{getSalaryInfo.data.uniqueCollege.name}</h3>
							<h5>
								{getSalaryInfo.data.uniqueCollege.city}, {getSalaryInfo.data.uniqueCollege.state}
							</h5>
							<br />
							<h6>
								<b>Average Early Salary =&nbsp;</b>{" "}
								{currencyFormatter.format(getSalaryInfo.data.uniqueCollege.earlyPay)}&nbsp;
								<ToolTip text={"The salary expected directly out of college"} />
							</h6>
							<h6>
								<b>Average Mid Salary =&nbsp;</b> {currencyFormatter.format(getSalaryInfo.data.uniqueCollege.midPay)}
								&nbsp;
								<ToolTip text={"The salary expected halfway through your career"} />
							</h6>
							<h6>
								<b>Percentage of STEM Students =&nbsp;</b>
								{getSalaryInfo.data.uniqueCollege.stem > 0 ? getSalaryInfo.data.uniqueCollege.stem + "%" : "N/A"}
							</h6>
							<h6>
								<b>Percentage High Meaning Job =&nbsp;</b>
								{getSalaryInfo.data.uniqueCollege.highJobMeaning > 0
									? getSalaryInfo.data.uniqueCollege.highJobMeaning + "%"
									: "N/A"}
							</h6>
							<h6>
								<b>Rank =&nbsp;</b>
								{getSalaryInfo.data.uniqueCollege.rank > 0 ? getSalaryInfo.data.uniqueCollege.rank : "N/A"}
							</h6>
							<button
								onClick={() => {
									setMsg(`Adding ${getSalaryInfo.data.uniqueCollege.name} to list...`);
									collegeCreation.mutate({ iped: getSalaryInfo.data ? getSalaryInfo.data.uniqueCollege.iped : "" });
								}}
								style={{
									display: props.colleges.find((p) => p.iped == getSalaryInfo.data.uniqueCollege.iped)
										? "none"
										: "block"
								}}>
								Add to List
							</button>
						</SchoolInfo>
						<table>
							<thead>
								<tr>
									<th>Major</th>
									<th></th>
									<th>Rank</th>
									<th>Early Career</th>
									<th>Mid Career</th>
								</tr>
							</thead>
							<tbody>
								{getSalaryInfo.data.fields.map((major) => (
									<>
										<tr>
											<td
												style={{
													textAlign: "left",
													paddingLeft: "15px",
													width: "260px"
												}}>
												{major.major}
											</td>
											<td>
												{major.major == getMajor() ? (
													<SelectButton
														style={{
															backgroundColor: "white",
															border: "2px solid var(--croi-green)"
														}}
														disabled={true}>
														SELECTED
													</SelectButton>
												) : (
													<SelectButton onClick={() => handleSelect(major.major)} disabled={collegeUpdate.isLoading}>
														SELECT
													</SelectButton>
												)}
											</td>
											<td>{major.rank}</td>
											<td>{currencyFormatter.format(major.earlyPay)}</td>
											<td>{currencyFormatter.format(major.midPay)}</td>
										</tr>
										<tr style={{ background: "transparent", height: "10px" }} />
									</>
								))}
							</tbody>
						</table>
					</CurrentCollege>
				);
			} else {
				return (
					<CurrentCollege>
						<div style={{ width: "100%" }}>
							<h3>{getSalaryInfo.data.uniqueCollege.name}</h3>
							<h5>
								{getSalaryInfo.data.uniqueCollege.city}, {getSalaryInfo.data.uniqueCollege.state}
							</h5>
							<br />
							<h6>Average Early Salary {currencyFormatter.format(getSalaryInfo.data.uniqueCollege.earlyPay)}</h6>
							<h6>Average Mid Salary {currencyFormatter.format(getSalaryInfo.data.uniqueCollege.midPay)}</h6>
						</div>
					</CurrentCollege>
				);
			}
		} else {
			return <CurrentCollege>No College Selected</CurrentCollege>;
		}
	};

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			<SalaryMaster>
				<h2
					style={{
						textAlign: "center",
						fontWeight: "bold"
					}}>
					Search By College
				</h2>
				<p>
					<em>
						Search any school to receive a comprehensive breakdown of early and mid-career median salaries by field of
						study, along with valuable post-graduation employment information to guide your career planning.
					</em>
				</p>
				<SalarySearch>
					<input
						className="p-2 w-100"
						type="text"
						placeholder={"Search College"}
						value={searchResults.slug}
						onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
					/>
					{searchResults.slug !== "" && (
						<SalarySearchBox>
							{searchResults.loading && (
								<SalarySearchItem>
									<div style={{ margin: "50px auto 50px auto" }}>
										<ScaleLoader color="black" loading={searchResults.loading} />
									</div>
								</SalarySearchItem>
							)}
							{searchResults.results && (
								<SalarySearchBar>
									{searchResults.results.map((value) => {
										return (
											<SalarySearchItem key={value.id} onClick={() => handleItemClick(value.id)}>
												{value["school.name"]}
											</SalarySearchItem>
										);
									})}
								</SalarySearchBar>
							)}
						</SalarySearchBox>
					)}
				</SalarySearch>
				{displayCollege()}
				<h2 style={{ marginTop: "15px" }}>My College List</h2>
				<CardSection>
					{props.colleges.map((school) => (
						<Card>
							<CardHeader>
								<DeleteCollegeButton
									onClick={() => {
										if (currentSchool === school.id) {
											setCurrentSchool("");
										}
										deleteQuery.mutate({ id: school.id });
									}}>
									X
								</DeleteCollegeButton>
								{school.name}
							</CardHeader>
							<p>Early Career Salary</p>
							<h1>{currencyFormatter.format(school.earlyPay)}</h1>
							<p>Mid-Career Salary</p>
							<h1>{currencyFormatter.format(school.midPay)}</h1>
							<CardButton onClick={() => handleItemClick(school.iped)}>See Details</CardButton>
						</Card>
					))}
				</CardSection>
			</SalaryMaster>
		</>
	);
}

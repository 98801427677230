import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { AddBulk, AddStudent, ImportStudentButtons, ModalStyle, ResetButton, SortButton } from "./AdminPage.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addUser, addUsers, fetchUsers } from "../../services/User.services";
import { altUser, User } from "../../types/User.types";
import { Modal } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";
import StudentListOrganizer from "../../util/StudentListOrganizer";
import { Snack } from "../../atoms/SnackBarAlert";
import { queryClient } from "../../services/QueryClient.services";

type SingleUser = {
	adminID: string;
	firstName: string;
	lastName: string;
	email: string;
	password?: string;
};

const singleUser: SingleUser = {
	adminID: "",
	firstName: "",
	lastName: "",
	email: ""
};

type bodyData = {
	id: string;
	items: string[];
};

export function AdminPage(props: { admin: User }) {
	const [data, setData] = useState(singleUser);
	const [showAdd, setShowAdd] = useState(false);
	const [showBulk, setShowBulk] = useState(false);
	const [csvFile, setCsvFile] = useState<File | null>();
	const [userList, setUserList] = useState<altUser[]>([]);
	const [snackMsg, setMsg] = useState("");

	const getUsers = useQuery<altUser[], Error>({
		queryKey: ["usersList"],
		queryFn: () => fetchUsers()
	});

	const createUser = useMutation({
		mutationKey: ["usersList"],
		mutationFn: (body: any) => addUser(body),
		onSuccess: () => {
			setMsg("Created User Successfully!");
			queryClient.invalidateQueries(["usersList"]);
		}
	});

	const createUsers = useMutation({
		mutationKey: ["usersList"],
		mutationFn: (body: any) => addUsers(body),
		onSuccess: () => {
			setMsg("Created Users Successfully!");
			queryClient.invalidateQueries(["usersList"]);
		}
	});

	const createSingleUser = () => {
		setShowAdd(false);

		const body = {
			adminID: props.admin.id,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			password: data.password && data.password.length > 0 ? data.password : null
		};

		createUser.mutate(body);
	};

	const createBulkUser = async () => {
		createUsers.mutate(csvFile);
		setShowBulk(false);
	};

	const addFunc = () => {
		setData(singleUser);
		setShowAdd(true);
	};

	const modalView = () => {
		return (
			<Modal show={showAdd} onHide={() => setShowAdd(!showAdd)}>
				<ModalStyle>
					<Modal.Title style={{ margin: "10px" }}>Add User</Modal.Title>
					<div>
						<p>firstName: </p>
						<input
							type="text"
							placeholder={"firstName"}
							value={data.firstName}
							onChange={(e) => setData({ ...data, firstName: e.target.value })}
						/>
					</div>

					<div>
						<p>lastName: </p>
						<input
							type="text"
							placeholder={"lastName"}
							value={data.lastName}
							onChange={(e) => setData({ ...data, lastName: e.target.value })}
						/>
					</div>

					<div>
						<p>Email: </p>
						<input
							type="text"
							placeholder={"email"}
							value={data.email}
							onChange={(e) => setData({ ...data, email: e.target.value })}
						/>
					</div>

					<div>
						<p>Password: </p>
						<input
							type="text"
							placeholder={"optional password"}
							value={data.password}
							onChange={(e) => setData({ ...data, password: e.target.value })}
						/>
					</div>

					<ResetButton onClick={() => createSingleUser()} style={{ margin: "10px" }}>
						Done
					</ResetButton>
				</ModalStyle>
			</Modal>
		);
	};

	const modalViewFile = () => {
		return (
			<Modal show={showBulk} onHide={() => setShowBulk(!showBulk)}>
				<ModalStyle>
					<Modal.Title style={{ margin: "10px" }}>Bulk Upload</Modal.Title>
					<div style={{ width: "95%", textAlign: "center" }}>
						You must add a file with the columns named (without quotations) "firstName", "lastName", & "email".
					</div>
					<br />
					<div style={{ width: "95%", textAlign: "center" }}>
						You may also add an optional column "password" to manually set the password.
					</div>
					<br />
					<div style={{ width: "95%", textAlign: "center" }}>
						After successfully uploading the csv, it may take a few minutes for your students to appear
					</div>
					<br />
					<div>
						<p>CSV File:</p>
						<input type="file" accept=".csv" onChange={(e) => setCsvFile(e.target.files ? e.target.files[0] : null)} />
					</div>
					<div>{csvFile && <p>Selected CSV File: {csvFile.name}</p>}</div>
					<ResetButton onClick={() => createBulkUser()} style={{ margin: "10px" }}>
						Bulk Upload
					</ResetButton>
				</ModalStyle>
			</Modal>
		);
	};

	const theadData = ["First Name", "Last Name", "Student ID", "Email", "Actions"];

	const tbodyData = () => {
		const data: bodyData[] = [];

		if (userList.length > 0) {
			for (let i = 0; i < userList.length; i++) {
				const items: string[] = [userList[i].firstName, userList[i].lastName, userList[i].id, userList[i].email];
				data.push({
					id: userList[i].id,
					items: items
				});
			}
		}

		return data;
	};

	const renderContent = () => {
		if (getUsers.isLoading && userList.length <= 0) {
			return (
				<>
					<div>Retrieving Users...</div>
					<ScaleLoader color={"black"} loading={userList.length == 0} />
				</>
			);
		} else {
			return <Table tHead={theadData} tBody={tbodyData()} setMsg={setMsg} />;
		}
	};

	useEffect(() => {
		if (getUsers.data) {
			setUserList(getUsers.data);
		}
	}, [getUsers.data]);

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			{modalView()}
			{modalViewFile()}
			<div style={{ padding: "0px 0px 0px 80px" }}>
				<ImportStudentButtons>
					<div style={{ width: "70%", height: "50px", display: "flex" }}>
						<p style={{ marginTop: "1.5%" }}>Sort By:</p>
						<SortButton
							onClick={() => setUserList(StudentListOrganizer(userList, "first"))}
							disabled={getUsers.isLoading}>
							First Name
						</SortButton>
						<SortButton
							onClick={() => setUserList(StudentListOrganizer(userList, "last"))}
							disabled={getUsers.isLoading}>
							Last Name
						</SortButton>
						<SortButton
							onClick={() => setUserList(StudentListOrganizer(userList, "email"))}
							disabled={getUsers.isLoading}>
							Email
						</SortButton>
					</div>
					<div style={{ width: "51.5%", height: "50px", display: "flex" }}>
						<p style={{ marginTop: "2%" }}>Add Users:</p>
						<AddStudent onClick={() => addFunc()} disabled={getUsers.isLoading}>
							Single
						</AddStudent>
						<AddBulk onClick={() => setShowBulk(true)} disabled={getUsers.isLoading}>
							Bulk
						</AddBulk>
					</div>
				</ImportStudentButtons>
				{renderContent()}
			</div>
		</>
	);
}
